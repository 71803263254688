import { __ } from '@wordpress/i18n';
import wNumb from 'wnumb';

export const calcTotalSum = (array: calcPayment[]) => array.reduce((sum, current) => sum + current.value, 0);

export const screens = {
	sm: '640px',
	md: '768px',
	lg: '1024px',
	xl: '1280px',
	'2xl': '1536px',
};

export const engineTypes = [
	'gasoline',
	'diesel',
	'gas',
	'gas-gasoline',
	'hybrid',
	'electric',
	'methane',
	'propane-butane',
	'other',
] as const;

export type engineType = typeof engineTypes[number];

type calcClearence = {
	engineType: engineType;
	engineVolume: number;
	graduationYear: number;
	costOfTheCar: number;
};

type calcPayment = {
	name: string;
	value: number;
	payments?: calcPayment[];
};

type calcResult = {
	payments: calcPayment[];
	notes: string[];
	total: calcPayment;
};

type calcShipping = {
	auctions: any;
	seaShippingPrice: any;
	costOfTheCar: number;
	downloadToContainerAndDocuments: number;
	auction: 'copart' | 'iaai';
	auctionCity: string;
	portFrom: string;
	commision: number;
	expedition: number;
	broker: number;
	insurance?: boolean;
	otherFees: number;
	portTo: 'klaipeda' | 'gdynia' | 'bremen' | 'odessa';
};

type calcShippAndClearence = {
	costOfTheCar: number;
	auction: 'copart' | 'iaai';
	auctionCity: string;
	portFrom: string;
	portTo: 'klaipeda' | 'gdynia' | 'bremen' | 'odessa';
	engineType: engineType;
	engineVolume: number;
	graduationYear: number;
};

export const toggleLoader = (element: HTMLElement) => {
	element.classList.toggle('loading');
	
};

export const calcClearence = (params: calcClearence) => {
	let rateEUR: number;
	let coeffAge: number = new Date().getFullYear() - +params.graduationYear - 1;
	let excise: number;
	let customs: number;
	let vat: number;

	if (coeffAge > 15) {
		coeffAge = 15;
	} else if (coeffAge < 1) {
		coeffAge = 1;
	}

	switch (params.engineType) {
		case 'electric':
			excise = Math.round(+params.engineVolume * theme.calc.rate_EUR_USD);
			customs = 0;
			vat = 0;

		case 'diesel':
			+params.engineVolume > 3500 ? (rateEUR = 150) : (rateEUR = 75);
			excise = Math.round(rateEUR * (+params.engineVolume / 1000) * coeffAge * +theme.calc.rate_EUR_USD);
			customs = Math.round(+params.costOfTheCar * 0.1);
			vat = Math.round((+params.costOfTheCar + customs + excise) * 0.2);

		default:
			+params.engineVolume > 3000 ? (rateEUR = 100) : (rateEUR = 50);
			excise = Math.round(rateEUR * (+params.engineVolume / 1000) * coeffAge * +theme.calc.rate_EUR_USD);
			customs = Math.round(+params.costOfTheCar * 0.1);
			vat = Math.round((+params.costOfTheCar + customs + excise) * 0.2);
	}

	let payments = [
		{
			name: 'excise',
			value: Math.round(excise),
		},
		{
			name: 'customs',
			value: Math.round(customs),
		},
		{
			name: 'vat',
			value: Math.round(vat),
		},
		{
			name: 'pension_fund',
			value: Math.round((+params.costOfTheCar + excise + customs + vat) * 0.03),
		},
		{
			name: 'sertificate',
			value: 150,
		},
		{
			name: 'shipping_land_ua',
			value: 300,
		},
	];

	return {
		payments: payments,
		notes: [],
		total: {
			name: __('Всього', 'frontenda-store-front'),
			value: calcTotalSum(payments),
		},
	};
};

export const calcShipping = (params: calcShipping) => {
	let paymentsUSA: calcPayment[] = [
		{
			name: 'bid',
			value: Math.round(+params.costOfTheCar),
		},
		{
			name: 'insurance',
			value: params.insurance ? Math.round(calcInsurance(+params.costOfTheCar)) : 0,
		},
		{
			name: 'auction_fee',
			value: Math.round(calcAuctionFee(params.auction, +params.costOfTheCar)),
		},
		{
			name: 'commision',
			value: Math.round(params.commision),
		},
	];
	let paymentsUA: calcPayment[] = [
		{
			name: 'shipping_land',
			value: Math.round(+params.auctionCity),
		},
		{
			name: 'download',
			value: Math.round(params.downloadToContainerAndDocuments),
		},
		{
			name: 'shipping_sea',
			value: Math.round(deliverySeaUsa(params)),
		},
		{
			name: 'expedition',
			value: Math.round(params.expedition),
		},
		{
			name: 'broker',
			value: Math.round(params.broker),
		},
		{
			name: 'other_fees',
			value: Math.round(params.otherFees),
		},
	];
	let payments = [
		{
			payments: paymentsUSA,
			name: __('Витрати в США', 'frontenda-store-front'),
			value: calcTotalSum(paymentsUSA),
		},
		{
			payments: paymentsUA,
			name: __('Витрати в порту призначення', 'frontenda-store-front'),
			value: calcTotalSum(paymentsUA),
		},
	];
	let paymentsObj: any = {};

	payments.forEach((el) => {
		paymentsObj[el.name] = el.value;
		el.payments.forEach((payment) => {
			paymentsObj[payment.name] = payment.value;
		});
	});
	return {
		payments: payments,
		paymentsObj: paymentsObj,
		notes: [
			'*розвантаження контейнера з судна, доставка конт. до терміналу, розвантаження авто та стоянка в порту',
		],
		total: {
			name: __('Всього', 'frontenda-store-front'),
			value: calcTotalSum(payments),
		},
	};
};

export const serializeForm = (form: HTMLFormElement) => {
	let obj: any = {};
	const formData = new FormData(form);
	for (var key of formData.keys()) {
		obj[key] = formData.get(key);
	}
	return obj;
};

export const moneyFormat = wNumb({
	mark: '.',
	thousand: ' ',
	suffix: ' $',
});

export const numbFormat = wNumb({
	mark: '.',
	thousand: ' ',
});
// export const toPrice = new Intl.NumberFormat('uk-UA', {
// 	style: 'currency',
// 	currency: 'USD',
// 	currencyDisplay: 'narrowSymbol',
// 	minimumFractionDigits: 0,
// 	maximumFractionDigits: 0,
// });

export function calcAuctionFee(auction: string, price: number) {
	let auctionFee = 0;
	if (auction == 'copart') {
		switch (true) {
			case price <= 49.99:
				auctionFee = 27.5;
				break;
			case 50 <= price && price <= 99.99:
				auctionFee = 40;
				break;
			case 100 <= price && price <= 199.99:
				auctionFee = 65;
				break;
			case 200 <= price && price <= 299.99:
				auctionFee = 90;
				break;
			case 300 <= price && price <= 349.99:
				auctionFee = 110;
				break;
			case 350 <= price && price <= 399.99:
				auctionFee = 110;
				break;
			case 400 <= price && price <= 449.99:
				auctionFee = 130;
				break;
			case 450 <= price && price <= 499.99:
				auctionFee = 130;
				break;
			case 500 <= price && price <= 549.99:
				auctionFee = 150;
				break;
			case 550 <= price && price <= 599.99:
				auctionFee = 150;
				break;
			case 600 <= price && price <= 699.99:
				auctionFee = 170;
				break;
			case 700 <= price && price <= 799.99:
				auctionFee = 190;
				break;
			case 800 <= price && price <= 899.99:
				auctionFee = 210;
				break;
			case 900 <= price && price <= 999.99:
				auctionFee = 230;
				break;
			case 1000 <= price && price <= 1199.99:
				auctionFee = 255;
				break;
			case 1200 <= price && price <= 1299.99:
				auctionFee = 290;
				break;
			case 1300 <= price && price <= 1399.99:
				auctionFee = 305;
				break;
			case 1400 <= price && price <= 1499.99:
				auctionFee = 315;
				break;
			case 1500 <= price && price <= 1599.99:
				auctionFee = 325;
				break;
			case 1600 <= price && price <= 1699.99:
				auctionFee = 345;
				break;
			case 1700 <= price && price <= 1799.99:
				auctionFee = 355;
				break;
			case 1800 <= price && price <= 1999.99:
				auctionFee = 375;
				break;
			case 2000 <= price && price <= 2399.99:
				auctionFee = 415;
				break;
			case 2400 <= price && price <= 2499.99:
				auctionFee = 425;
				break;
			case 2500 <= price && price <= 2999.99:
				auctionFee = 455;
				break;
			case 3000 <= price && price <= 3499.99:
				auctionFee = 565;
				break;
			case 3500 <= price && price <= 3999.99:
				auctionFee = 615;
				break;
			case 4000 <= price && price <= 4499.99:
				auctionFee = 640;
				break;
			case 4500 <= price && price <= 4999.99:
				auctionFee = 665;
				break;
			case 5000 <= price && price <= 5999.99:
				auctionFee = 765;
				break;
			case 6000 <= price && price <= 7499.99:
				auctionFee = 790;
				break;
			case 7500 <= price && price <= 9999.99:
				auctionFee = 890;
				break;
			case 10000 <= price && price <= 14999.99:
				auctionFee = 1050;
				break;
			case 15000 <= price && price <= 10000000:
				auctionFee = Math.round(price * 0.09);
				break;
			case price > 10000000:
				auctionFee = Math.round(price * 0.25);
				break;
		}
		auctionFee += 59; // Сбор за обработку (Gate Fee)

		switch (true) {
			case price <= 99.99:
				auctionFee += 0;
				break;
			case 100 <= price && price <= 499.99:
				auctionFee += 39;
				break;
			case 500 <= price && price <= 999.99:
				auctionFee += 49;
				break;
			case 1000 <= price && price <= 1499.99:
				auctionFee += 69;
				break;
			case 1500 <= price && price <= 1999.99:
				auctionFee += 79;
				break;
			case 2000 <= price && price <= 3999.99:
				auctionFee += 89;
				break;
			case 4000 <= price && price <= 5999.99:
				auctionFee += 99;
				break;
			case 6000 <= price && price <= 7999.99:
				auctionFee += 119;
				break;
			case 8000 <= price && price <= 10000000:
				auctionFee += 129;
				break;
		}
	}
	if (auction == 'iaai') {
		switch (true) {
			case price <= 99.99:
				auctionFee = 1;
				break;
			case 100 <= price && price <= 199.99:
				auctionFee = 40;
				break;
			case 200 <= price && price <= 299.99:
				auctionFee = 60;
				break;
			case 300 <= price && price <= 349.99:
				auctionFee = 75;
				break;
			case 350 <= price && price <= 399.99:
				auctionFee = 90;
				break;
			case 400 <= price && price <= 499.99:
				auctionFee = 100;
				break;
			case 500 <= price && price <= 599.99:
				auctionFee = 130;
				break;
			case 600 <= price && price <= 699.99:
				auctionFee = 145;
				break;
			case 700 <= price && price <= 799.99:
				auctionFee = 160;
				break;
			case 800 <= price && price <= 899.99:
				auctionFee = 175;
				break;
			case 900 <= price && price <= 999.99:
				auctionFee = 190;
				break;
			case 1000 <= price && price <= 1099.99:
				auctionFee = 205;
				break;
			case 1100 <= price && price <= 1199.99:
				auctionFee = 220;
				break;
			case 1200 <= price && price <= 1299.99:
				auctionFee = 230;
				break;
			case 1300 <= price && price <= 1399.99:
				auctionFee = 240;
				break;
			case 1400 <= price && price <= 1499.99:
				auctionFee = 255;
				break;
			case 1500 <= price && price <= 1599.99:
				auctionFee = 270;
				break;
			case 1600 <= price && price <= 1699.99:
				auctionFee = 290;
				break;
			case 1700 <= price && price <= 1799.99:
				auctionFee = 300;
				break;
			case 1800 <= price && price <= 1999.99:
				auctionFee = 310;
				break;
			case 2000 <= price && price <= 2199.99:
				auctionFee = 325;
				break;
			case 2200 <= price && price <= 2399.99:
				auctionFee = 330;
				break;
			case 2400 <= price && price <= 2499.99:
				auctionFee = 345;
				break;
			case 2500 <= price && price <= 2999.99:
				auctionFee = 360;
				break;

			case 3000 <= price && price <= 3499.99:
				auctionFee = 400;
				break;
			case 3500 <= price && price <= 3999.99:
				auctionFee = 450;
				break;
			case 4000 <= price && price <= 4499.99:
				auctionFee = 475;
				break;
			case 4500 <= price && price <= 4999.99:
				auctionFee = 500;
				break;
			case 5000 <= price && price <= 5999.99:
				auctionFee = 525;
				break;
			case 6000 <= price && price <= 7499.99:
				auctionFee = 550;
				break;
			case 7500 <= price && price <= 19999.99:
				auctionFee = 500 + Math.round(price * 0.01);
				break;
			case 20000 <= price:
				auctionFee = Math.round(price * 0.04);
				break;
		}
		switch (true) {
			case price <= 99.99:
				auctionFee += 0;
				break;
			case 100 <= price && price <= 499.99:
				auctionFee += 29;
				break;
			case 500 <= price && price <= 999.99:
				auctionFee += 39;
				break;
			case 1000 <= price && price <= 1499.99:
				auctionFee += 59;
				break;
			case 1500 <= price && price <= 1999.99:
				auctionFee += 69;
				break;
			case 2000 <= price && price <= 3999.99:
				auctionFee += 79;
				break;
			case 4000 <= price && price <= 5999.99:
				auctionFee += 89;
				break;
			case 6000 <= price && price <= 7999.99:
				auctionFee += 99;
				break;
			case 8000 <= price:
				auctionFee += 119;
				break;
		}
		auctionFee += 79;
		auctionFee += 10;
	}

	return auctionFee;
}

function deliverySeaUsa(params: calcShipping) {
	const dataPort = params.seaShippingPrice.find((el: { port_to: string }) => el.port_to == params.portTo);
	return +dataPort[params.portFrom];
}

function calcInsurance(price: number): number {
	return price * 0.03 < 150 ? 150 : Math.round(price * 0.03);
}
